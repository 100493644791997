<template>
  <a-layout class="admin-project-con my-layout">
    <!-- <a-layout-header class="header">
      <div class="logo" />

    </a-layout-header> -->
    <a-layout>
      <a-layout-sider
        :width="280"
        class="admin-project-layout-sider"
        breakpoint="lg"
        :collapsed-width="0"
        v-model:collapsed="collapsed"
        :trigger="null"
        @breakpoint="onBreakpoint">
        <a-menu
          v-model:selectedKeys="siderSelectedKeys"
          mode="inline"
          @select="handleSiderSelect"
          :inlineIndent="16"
          style="padding-top: 8px;"
        >
            <div class="admin-project-layout-sider-inner-wrap">
                <!-- <a-menu-item :key="projectPath.PROJECT_INFO">
                    <UserOutlined class="menu-item-icon" />
                    {{$t('project.menu.projectInfo')}}
                </a-menu-item> -->
                <a-menu-item class="my-menu-group-title" disabled>{{$t('project.projectSettings')}}</a-menu-item>
                <a-menu-item :key="projectPath.PROJECT_MEMBERS">
                    <UserOutlined class="menu-item-icon" />
                    {{$t('project.menu.projectMembers')}}
                </a-menu-item>
                <!-- <a-menu-item :key="projectPath.PROJECT_ROLE">
                    <UserOutlined class="menu-item-icon" />
                    {{$t('project.menu.projectRole')}}
                </a-menu-item>
                <a-menu-item :key="projectPath.PROJECT_APPS_MANAGEMENT">
                    <UserOutlined class="menu-item-icon" />
                    {{$t('project.menu.projectAppsManagement')}}
                </a-menu-item> -->
            </div>

        </a-menu>
      </a-layout-sider>
      <a-layout class="admin-project-layout-conten-wrap" :style="isBreakpoint ? '' : 'padding-left: 16px;'">
        <a-layout-content class="admin-project-layout-content">
          <div class="my-trigger-wrap center" v-if="isBreakpoint">
            <menu-unfold-outlined
              v-if="collapsed"
              class="trigger"
              @click="() => (collapsed = !collapsed)"
            />
            <menu-fold-outlined
              v-else
              class="trigger"
              @click="() => (collapsed = !collapsed)" />
          </div>
          <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-layout>
</template>
<script>
import { UserOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons-vue'
import { defineComponent, onMounted, reactive, toRefs, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { admin } from '@/router/path'
const { project } = admin
export default defineComponent({
  components: {
    UserOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined
  },

  setup () {
    const router = useRouter()
    const store = useStore()
    const { t } = useI18n()
    // console.log(router)
    const state = reactive({
      siderSelectedKeys: [project.PROJECT_INFO],
      projectCapacity: 66,
      personnelQuota: 20,
      isBreakpoint: false,
      collapsed: false,
      projectPath: project
    })

    const handleSiderSelect = ({ key }) => {
      switch (key) {
        case project.PROJECT_INFO:
          // 项目信息
          break
        case project.PROJECT_MEMBERS:
          // 项目人员
          break
        case project.PROJECT_ROLE:
          // 项目角色
          break
        case project.PROJECT_APPS_MANAGEMENT:
          // 项目应用管理
          break
        default:
          break
      }
      router.replace(key)
    }

    const onBreakpoint = (val) => {
      state.isBreakpoint = val
    }

    onMounted(() => {
      store.dispatch('setAdminNavtitleAction', t('project.projectSettings'))
      state.siderSelectedKeys = [router.currentRoute.value.name]
    })

    watchEffect(() => {
      if (store.state.locale) {
        store.dispatch('setAdminNavtitleAction', t('project.projectSettings'))
      }
    })

    return {
      ...toRefs(state),
      onBreakpoint,
      handleSiderSelect
    }
  }

})
</script>

<style lang="less">
.admin-project-con {
  width: 100%;
  height: 100%;
  background: #EDEDED;
  position: relative;
  .admin-project-layout-sider {
    // height: calc(100% - 10px);
    // margin-top: 10px;
    width: 280px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2)!important;
    border-radius: 4px;
    overflow: hidden;
    .ant-menu {
      height: 100%;
      position: relative;
      .admin-project-layout-sider-inner-wrap {
        padding-bottom: 12px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 6px;
        .my-menu-group-title {
          font-size: 12px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.4)!important;
          line-height: 17px;
          // padding: 12px 16px;
        }

        .menu-item-icon {
            margin-right: 8px;
        }
        .my-divider {
            width: calc(100% - 32px);
            height: 1px;
            background-color: rgba(0, 0, 0, 0.1);
            margin: 16px auto 0;
        }
        .ant-menu-item {
            height: 48px;
            line-height: 48px;
            margin: 0;
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.8);
        }
        .ant-menu-item-selected {
            color: #209FFD;
        }

      }

    }

  }
  .admin-project-layout-conten-wrap {
    // padding: 10px 0 0px 0;
    position: relative;
    .admin-project-layout-content {
      padding: 24px;
      background: #FFFFFF;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      min-width: 300px;
    }
  }
  .my-trigger-wrap {
    position: absolute;
    width: 28px;
    height: 20px;
    line-height: 21px;
    cursor: pointer;
    left: 0;
    top: 0;
    font-size: 14px;
    font-weight: 600;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0 4px 4px 0;
    color: rgba(0, 0, 0, 0.6);
    z-index: 9;
    background-color: #fff;
  }
}
</style>
